<!-- vue coding=utf-8 -->
<!--
 * @Author: Chen-
 * @Description: 提现
 * @Date: 2020-12-24 18:08:15
 * @LastEditors: OBKoro1
 * @LastEditTime: 2020-12-25 17:20:11
 * @FilePath: /yue_quanzhan_h5_new/src/views/user/Withdrawal.vue
-->
<template>
  <div class="user_content">
    <div :class="bindCard === 0? 'bank_card':'bank_card bank'">
      <span>{{ bindCard | style }}银行卡</span><span v-if="bindCard === 0" @click="goauth">去认证</span>
    </div>
    <div class="money">
      <div class="money_inp">
        <van-field v-model="value" :label-width="20" label="￥" placeholder="请输入您的提现金额" />
      </div>
      <div class="money_tip">
        <span style="color: #666;">{{ cashText }}</span><span>{{ tips }}</span>
      </div>
    </div>
    <p class="warntip">{{ warning }}</p>
    <van-button class="money_btn" :disabled="disabled" @click="getWithdrawal">确认提现</van-button>
    <div class="tip">
      <ul>
        <li v-html="content" />
      </ul>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Button, Field, Toast } from 'vant'

Vue.use(Field).use(Button).use(Toast)
import {
  cashnewGet,
  cashnewApply
} from '@/services/userApi'

export default {
  filters: {
    style(v) {
      const map = {
        0: '请绑定您的',
        1: '已绑定'
      }
      return map[v]
    }
  },
  data() {
    return {
      value: '',
      cashText: '',
      tips: '',
      warning: '',
      content: '',
      bindCard: '',
      flag: '',
      bindUrl: '',
      disabled: true
    }
  },
  created() {
    this.getinfo()
  },
  methods: {
    getinfo() {
      cashnewGet().then(res => {
        if (Number(res.code) === 200) {
          this.cashText = res.data.cashText
          this.tips = res.data.tips
          this.warning = res.data.warning
          this.bindCard = res.data.bindCard
          this.flag = res.data.flag
          this.bindUrl = res.data.bindUrl
          this.content = res.data.content.replace(/\s+/g, '<br/>')
        } else {
          Toast(res.msg)
        }
      })
      this.disabled = this.flag === 1
    },
    goauth() {
      window.location.href = this.bindUrl
    },
    // 提现
    getWithdrawal() {
      if (this.value === '') {
        return Toast('请输入金额')
      }
      const pr = {
        money: this.value
      }
      cashnewApply(pr).then(res => {
        if (Number(res.code) === 200) {
          // TODO 此处为空
        } else {
          Toast(res.msg)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.user_content {
  width: 100%;
  height: auto;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f6f6f6;
  padding-bottom: 80px;

  .bank_card {
    background: #FFFFFF;
    margin: 13px 0;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > span:nth-child(1) {
      font-size: 14px;
    }

    & > span:nth-child(2) {
      color: #FFFFFF;
      background: #E33F44;
      padding: 6px 15px;
      border-radius: 15px;
    }
  }

  .bank {
    padding: 10px 10px;
  }

  .warntip {
    text-align: left;
    margin: 13px;
    font-size: 10px;
  }

  .money {
    background: #FFFFFF;
    padding: 15px 10px;

    .money_inp {
      border-bottom: 1px solid #F2F2F2;
      margin-bottom: 10px;
    }

    .money_tip {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & > span:nth-child(2) {
        font-size: 13px;
        color: #E33F44;
      }
    }
  }

  .money_btn {
    width: 90%;
    margin-top: 40px;
    background: #E33F44;
    color: #fff;
    padding: 12px 15px;
    border-radius: 10px;
  }

  .tip {
    margin: 50px 13px;
    color: #E33F44;
    text-align: left;

    & > ul li {
      line-height: 25px;
    }
  }
}

</style>
